<template>
    <div class="container">
        <div v-if="dataIndex.data.sections.index !== undefined" class="row">
            <div class="col-12">
                <!--                <BButton :variant="'light'" @click="modal = !modal">-->
                <!--                    Toggle modal-->
                <!--                </BButton>-->
                <!--                <ClientOnly>-->
                <!--                    <BModal v-model="modal" title="Hello, World!">-->
                <!--                        Foobar?-->
                <!--                        <template #footer>-->
                <!--                            <button class="btn btn-light" type="button" @click="modal = false">-->
                <!--                                Закрыть-->
                <!--                            </button>-->
                <!--                            <button class="btn btn-primary" type="button">-->
                <!--                                Сохранить-->
                <!--                            </button>-->
                <!--                        </template>-->
                <!--                    </BModal>-->
                <!--                </ClientOnly>-->
                <!--                <div v-if="dataIndex.data.sections.index.title !== null" class="row mt-3 mb-3 rounded-sm-bottom pr-bg-white">-->
                <!--                    <div class="col-12 pr-shadow-sm p-3 rounded-2">-->
                <!--                        <h2>{{ dataIndex.data.sections.index.title }}</h2>-->
                <!--                        {{ dataIndex.data.sections.index.description }}-->
                <!--                    </div>-->
                <!--                </div>-->
                <FrontendTitleDefault v-if="dataIndex.data.titles.index !== undefined" :title="dataIndex.data.titles.index" class="mt-3" />
                <div v-if="dataIndex.data.sections.index.articles.length" class="row">
                    <FrontendArticleCardLeft
                        v-for="(article, key) in dataIndex.data.sections.index.articles"
                        :key="key"
                        :article="article"
                    />
                </div>
                <div v-if="dataIndex.data.sections.index.sections.length" class="row">
                    <div v-for="(section, key) in dataIndex.data.sections.index.sections" :key="key" class="col-12">
                        <div v-if="section.title !== null && section.url !== null" class="row mb-3">
                            <div class="col-12">
                                <NuxtLink :to="section.url" :class="`title-left-line ${section.image !== null ? '__image' : ''}`">
                                    <div class="d-flex align-items-center">
<!--                                        <div v-if="section.icon !== null" class="mr-3 font-lg">-->
<!--                                            <IconFontAwesome :name="section.icon" />-->
<!--                                        </div>-->
                                        <div v-if="section.image !== null" class="mr-3">
                                            <img :src="section.image" :alt="section.title" class="--image">
                                        </div>
                                        <div>
                                            <h3 class="mb-0">
<!--                                                <span v-if="section.icon !== null">-->
<!--                                                    <IconFontAwesome :name="section.icon" />-->
<!--                                                </span>-->
                                                {{ section.title }}
                                            </h3>
                                            <p class="mb-0">
                                                {{ section.description }}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="--button">
                                            <IconFontAwesome :name="`chevron-right`" :placement="`right`" :title="`Показать все`" :empty-mobile-title="true" />
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>
                        </div>
                        <div v-if="section.title !== null && section.url === null" class="row rounded-sm-bottom pr-bg-white">
                            <div class="col-12 pr-shadow-sm p-3 rounded-2">
                                <h2>{{ section.title }}</h2>
                                {{ section.description }}
                            </div>
                        </div>
                        <div v-if="section.articles.length" class="row">
                            <FrontendArticleCardLeft
                                v-for="(article, key) in section.articles"
                                :key="key"
                                :article="article"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { BButton, BModal } from 'bootstrap-vue-next';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import FrontendArticleCardLeft from '@/components/Frontend/Article/FrontendArticleCardLeft.vue';
import FrontendTitleDefault from '@/components/Frontend/Title/FrontendTitleDefault.vue';
import IconFontAwesome from '@/components/Icons/IconFontAwesome.vue';
import { Breadcrumbs } from '@/hooks/Breadcrumbs';
import useSeo from '@/hooks/useSeo';
import { useStoreAppDataIndex } from '@/stores/app/data';
import { useStoreSystem } from '@/stores/system';
import { definePageMeta, useColorMode, useAppConfig } from '#imports';
const dataIndex = useStoreAppDataIndex();
const system = useStoreSystem();
const breadcrumbs = (new Breadcrumbs());
const { $setCookie, $getCookie } = useNuxtApp();
// const logger = useLogger('my-module');
if (process.client) {

    // logger.info('Hello from my module!');
}
//
const appConfig = useAppConfig();
//
breadcrumbs.init();
breadcrumbs.create();

useHead(useSeo().index(dataIndex.data.config));

const modal = ref(false);

definePageMeta({
    middleware: [
        'data',
    ],
});
</script>
